import { LabelsController } from './labelsController';
import model from './model';
import type { LabelsWidgetProps } from './types';

export default model.createController(({ $widget, $w, flowAPI }) => {
  const labelsController = new LabelsController($w);

  $widget.onPropsChanged((_, { data: nextData }: { data: LabelsWidgetProps }) => {
    labelsController.init(nextData);
  });

  return {
    pageReady: async () => {
      labelsController.init($widget.props.data);
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
