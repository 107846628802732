import type { I$W } from '@wix/yoshi-flow-editor';
import { PRICE_VARIANTS_WIDGET_COMPONENT_IDS } from './consts';
import { setPriceContainer } from 'root/utils/priceDisplay';
import type { DisplayedPriceVariant } from '@wix/restaurants-populated-item-client/types';

export class VariantsController {
  constructor(
    private $w: I$W,
    private priceFormatter?: (price: string, shouldDisplayVariantCurrency: boolean) => string | undefined
  ) {
    this.$w(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantsRepeater)?.onItemReady?.(
      async (
        $item: I$W,
        itemData: DisplayedPriceVariant & {
          _id: string;
          shouldDisplayVariantCurrency: boolean;
          shouldDisplayZeroPrice: boolean;
          isLast: boolean;
        }
      ) => {
        $item(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantName).text = itemData.name;

        const variantDivider = $item(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantDivider);
        if (itemData.isLast) {
          variantDivider.delete();
        } else {
          variantDivider.restore();
        }

        const variantHasNoPrice = Number(itemData.priceInfo?.price || '0.00') === 0;
        const variantPriceContainer = $item(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantPrice);
        if (variantHasNoPrice && !itemData.shouldDisplayZeroPrice) {
          await variantPriceContainer.delete();
        } else {
          variantPriceContainer.deleted && (await variantPriceContainer.restore());
          $item(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantPrice).text = this.priceFormatter?.(
            itemData.priceInfo?.price || '0.00',
            itemData.shouldDisplayVariantCurrency
          );
        }
      }
    );
  }

  updateCurrency(shouldDisplayVariantCurrency: boolean) {
    this.$w(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantsRepeater)?.onItemReady?.(
      ($item: I$W, itemData: DisplayedPriceVariant & { _id: string }) => {
        $item(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantPrice).text = this.priceFormatter?.(
          itemData.priceInfo?.price || '0.00',
          shouldDisplayVariantCurrency
        );
      }
    );
  }

  updateShouldDisplayZeroPrice(shouldDisplayZeroPrice: boolean) {
    this.$w(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantsRepeater)?.onItemReady?.(
      async ($item: I$W, itemData: DisplayedPriceVariant & { _id: string; shouldDisplayVariantCurrency: boolean }) => {
        setPriceContainer(
          $item,
          PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantPrice,
          itemData.priceInfo?.price,
          shouldDisplayZeroPrice,
          itemData.shouldDisplayVariantCurrency,
          this.priceFormatter
        );
      }
    );
  }

  init(priceVariants: DisplayedPriceVariant[], shouldDisplayVariantCurrency: boolean, shouldDisplayZeroPrice: boolean) {
    this.$w(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantsRepeater).data = priceVariants.map(
      ({ id, ...rest }, index) => ({
        _id: `${id}-${index}`,
        shouldDisplayVariantCurrency,
        shouldDisplayZeroPrice,
        isLast: index === priceVariants.length - 1,
        ...rest,
      })
    );
  }
}
