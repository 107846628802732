import type { Preset, Section } from 'root/types';
import { MENU_WIDGET_PRESET_IDS, PRESET_SECTIONS_IDS_MAPPER } from 'root/utils/consts';
import type { I$W } from '@wix/yoshi-flow-editor';
import type { ColumnsWidgetProps } from './types';
import type { PlaceholderImageDisplayValue, ZeroPriceDisplayValue } from 'root/components/Menu/panels/Settings/types';
import { divideSectionsIntoColumns } from './utils';

export class ColumnsController {
  constructor(private $w: I$W, private isMobile: boolean) {}

  init(data: ColumnsWidgetProps) {
    this.setSections(
      data.sections,
      data.preset,
      data.shouldDisplayCurrency,
      data.shouldDisplayVariantCurrency,
      data.zeroPriceDisplayOption,
      data.placeholderImageDisplayValue,
      data.zeroPriceDisplaySpecificSectionIds,
      data.sectionsWithPlaceholderImageIds,
      data.placeholderImage
    );
  }

  setColumnsProps(
    columnsIds: string[],
    columns: Section[][],
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    zeroPriceDisplaySpecificSectionIds: string[] | undefined,
    sectionsWithPlaceholderImageIds: string[] | undefined,
    placeholderImage: string | undefined
  ) {
    columnsIds.forEach((columnId, i) => {
      this.$w(columnId).data = {
        sections: columns[i],
        preset,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        zeroPriceDisplayOption,
        zeroPriceDisplaySpecificSectionIds,
        placeholderImageDisplayValue,
        sectionsWithPlaceholderImageIds,
        placeholderImage,
      };
    });
  }

  setSections(
    sections: Section[],
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    zeroPriceDisplaySpecificSectionIds: string[] | undefined,
    sectionsWithPlaceholderImageIds: string[] | undefined,
    placeholderImage: string | undefined
  ) {
    if (this.isMobile) {
      this.$w(PRESET_SECTIONS_IDS_MAPPER.listOneColumn[0]).data = {
        sections,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        zeroPriceDisplayOption,
        zeroPriceDisplaySpecificSectionIds,
        placeholderImageDisplayValue,
        sectionsWithPlaceholderImageIds,
        placeholderImage,
      };
    } else {
      switch (preset) {
        case MENU_WIDGET_PRESET_IDS.listTwoColumn: {
          const columns = divideSectionsIntoColumns(2, sections);
          this.setColumnsProps(
            PRESET_SECTIONS_IDS_MAPPER.listTwoColumn,
            columns,
            preset,
            shouldDisplayCurrency,
            shouldDisplayVariantCurrency,
            zeroPriceDisplayOption,
            placeholderImageDisplayValue,
            zeroPriceDisplaySpecificSectionIds,
            sectionsWithPlaceholderImageIds,
            placeholderImage
          );

          break;
        }
        case MENU_WIDGET_PRESET_IDS.listThreeColumn: {
          const columns = divideSectionsIntoColumns(3, sections);
          this.setColumnsProps(
            PRESET_SECTIONS_IDS_MAPPER.listThreeColumn,
            columns,
            preset,
            shouldDisplayCurrency,
            shouldDisplayVariantCurrency,
            zeroPriceDisplayOption,
            placeholderImageDisplayValue,
            zeroPriceDisplaySpecificSectionIds,
            sectionsWithPlaceholderImageIds,
            placeholderImage
          );

          break;
        }
        case MENU_WIDGET_PRESET_IDS.sideBySideTwoColumn: {
          const columns = divideSectionsIntoColumns(2, sections);
          this.setColumnsProps(
            PRESET_SECTIONS_IDS_MAPPER.sideBySideTwoColumn,
            columns,
            preset,
            shouldDisplayCurrency,
            shouldDisplayVariantCurrency,
            zeroPriceDisplayOption,
            placeholderImageDisplayValue,
            zeroPriceDisplaySpecificSectionIds,
            sectionsWithPlaceholderImageIds,
            placeholderImage
          );
          break;
        }
        case MENU_WIDGET_PRESET_IDS.listOneColumn:
        case MENU_WIDGET_PRESET_IDS.listOneColumnWithImage:
        case MENU_WIDGET_PRESET_IDS.sideBySideOneColumn:
        case MENU_WIDGET_PRESET_IDS.grid: {
          this.setColumnsProps(
            PRESET_SECTIONS_IDS_MAPPER.listOneColumn,
            [sections],
            preset,
            shouldDisplayCurrency,
            shouldDisplayVariantCurrency,
            zeroPriceDisplayOption,
            placeholderImageDisplayValue,
            zeroPriceDisplaySpecificSectionIds,
            sectionsWithPlaceholderImageIds,
            placeholderImage
          );
          break;
        }
        default: {
          const columns = divideSectionsIntoColumns(2, sections);
          this.setColumnsProps(
            PRESET_SECTIONS_IDS_MAPPER.listTwoColumn,
            columns,
            preset,
            shouldDisplayCurrency,
            shouldDisplayVariantCurrency,
            zeroPriceDisplayOption,
            placeholderImageDisplayValue,
            zeroPriceDisplaySpecificSectionIds,
            sectionsWithPlaceholderImageIds,
            placeholderImage
          );
        }
      }
    }
  }
}
