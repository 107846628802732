import type { LabelWithSvg } from 'root/types';
import type { I$W } from '@wix/yoshi-flow-editor';
import { LABELS_WIDGET_COMPONENT_IDS } from './consts';
import type { LabelsWidgetProps } from './types';

export class LabelsController {
  constructor(private $w: I$W) {
    this.$w(LABELS_WIDGET_COMPONENT_IDS.labelsRepeater)?.onItemReady?.(
      async ($item: I$W, itemData: LabelWithSvg & { _id: string }) => {
        $item(LABELS_WIDGET_COMPONENT_IDS.badge).text = itemData.name;
        $item(LABELS_WIDGET_COMPONENT_IDS.badge).alt = itemData.name;
        $item(LABELS_WIDGET_COMPONENT_IDS.badge).icon = { svgId: itemData.svgId };
      }
    );
  }

  init(data: LabelsWidgetProps) {
    const { labels } = data;
    this.$w(LABELS_WIDGET_COMPONENT_IDS.labelsRepeater).data = labels.map(({ id, ...rest }) => ({
      _id: id,
      ...rest,
    }));
  }
}
