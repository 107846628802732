import { SECTION_WIDGET_COMPONENT_IDS, ELEMENT_PROPERTY } from 'root/utils/consts';
import type { PopulatedSection, Preset } from 'root/types';
import type { I$W } from '@wix/yoshi-flow-editor';
import { setElementDataAndCollapseIfNeeded } from 'root/utils/setElementDataAndCollapseIfNeeded';
import type { ZeroPriceDisplayValue, PlaceholderImageDisplayValue } from 'root/components/Menu/panels/Settings/types';
import { getShouldDisplayZeroPrice, getShouldDisplayPlaceholderImage } from 'root/utils/settingsUtils';

export class SectionsController {
  constructor(private $w: I$W) {
    this.$w(SECTION_WIDGET_COMPONENT_IDS.sectionsRepeater)?.onItemReady?.(
      (
        $item: I$W,
        itemData: PopulatedSection & {
          _id: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
          shouldDisplayVariantCurrency: boolean;
          zeroPriceDisplayOption: ZeroPriceDisplayValue;
          placeholderImageDisplayValue: PlaceholderImageDisplayValue;
          zeroPriceDisplaySpecificSectionIds?: string[];
          sectionsWithPlaceholderImageIds?: string[];
          placeholderImage?: string;
        }
      ) => {
        $item(SECTION_WIDGET_COMPONENT_IDS.sectionTitle).text = itemData.name;

        setElementDataAndCollapseIfNeeded(
          $item,
          SECTION_WIDGET_COMPONENT_IDS.sectionDescription,
          ELEMENT_PROPERTY.TEXT,
          itemData.description
        );

        const shouldDisplayZeroPrice = getShouldDisplayZeroPrice(
          itemData.zeroPriceDisplayOption,
          itemData._id,
          itemData.zeroPriceDisplaySpecificSectionIds
        );

        const shouldDisplayPlaceholderImage = getShouldDisplayPlaceholderImage(
          itemData.placeholderImageDisplayValue,
          itemData._id,
          itemData.sectionsWithPlaceholderImageIds
        );

        $item(SECTION_WIDGET_COMPONENT_IDS.items).data = {
          items: itemData.items,
          preset: itemData.preset,
          shouldDisplayCurrency: itemData.shouldDisplayCurrency,
          shouldDisplayVariantCurrency: itemData.shouldDisplayVariantCurrency,
          shouldDisplayZeroPrice,
          shouldDisplayPlaceholderImage,
          placeholderImage: itemData.placeholderImage,
        };

        $item(SECTION_WIDGET_COMPONENT_IDS.sectionWrapper).accessibility.ariaAttributes.label = itemData.name;
      }
    );
  }

  updateCurrency(shouldDisplayCurrency: boolean) {
    this.$w(SECTION_WIDGET_COMPONENT_IDS.sectionsRepeater)?.onItemReady?.(($item: I$W) => {
      $item(SECTION_WIDGET_COMPONENT_IDS.items).data.shouldDisplayCurrency = shouldDisplayCurrency;
    });
  }

  updateVariantCurrency(shouldDisplayVariantCurrency: boolean) {
    this.$w(SECTION_WIDGET_COMPONENT_IDS.sectionsRepeater)?.onItemReady?.(($item: I$W) => {
      $item(SECTION_WIDGET_COMPONENT_IDS.items).data.shouldDisplayVariantCurrency = shouldDisplayVariantCurrency;
    });
  }

  updatePlaceHolderImage(placeholderImage?: string) {
    this.$w(SECTION_WIDGET_COMPONENT_IDS.sectionsRepeater)?.onItemReady?.(($item: I$W) => {
      $item(SECTION_WIDGET_COMPONENT_IDS.items).data.placeholderImage = placeholderImage;
    });
  }

  updateZeroPriceDisplayOption(
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    zeroPriceDisplaySpecificSectionIds?: string[]
  ) {
    this.$w(SECTION_WIDGET_COMPONENT_IDS.sectionsRepeater)?.onItemReady?.(
      (
        $item: I$W,
        itemData: PopulatedSection & {
          _id: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
          shouldDisplayVariantCurrency: boolean;
          zeroPriceDisplayOption: ZeroPriceDisplayValue;
          zeroPriceDisplaySpecificSectionIds?: string[];
          placeholderImageDisplayValue: PlaceholderImageDisplayValue;
          sectionsWithPlaceholderImageIds?: string[];
          placeholderImage?: string;
        }
      ) => {
        const shouldDisplayZeroPrice = getShouldDisplayZeroPrice(
          zeroPriceDisplayOption,
          itemData._id,
          zeroPriceDisplaySpecificSectionIds
        );
        $item(SECTION_WIDGET_COMPONENT_IDS.items).data.shouldDisplayZeroPrice = shouldDisplayZeroPrice;
      }
    );
  }

  updatePlaceholderImageDisplayValue(
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    sectionsWithPlaceholderImageIds?: string[],
    placeholderImage?: string
  ) {
    this.$w(SECTION_WIDGET_COMPONENT_IDS.sectionsRepeater)?.onItemReady?.(
      (
        $item: I$W,
        itemData: PopulatedSection & {
          _id: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
          shouldDisplayVariantCurrency: boolean;
          zeroPriceDisplayOption: ZeroPriceDisplayValue;
          placeholderImageDisplayValue: PlaceholderImageDisplayValue;
          zeroPriceDisplaySpecificSectionIds: string[];
          sectionsWithPlaceholderImageIds: string[];
          placeholderImage: string;
        }
      ) => {
        const shouldDisplayPlaceholderImage = getShouldDisplayPlaceholderImage(
          placeholderImageDisplayValue,
          itemData._id,
          sectionsWithPlaceholderImageIds
        );
        $item(SECTION_WIDGET_COMPONENT_IDS.items).data = {
          ...$item(SECTION_WIDGET_COMPONENT_IDS.items).data,
          shouldDisplayPlaceholderImage,
          placeholderImage,
        };
      }
    );
  }

  init(
    sections: PopulatedSection[],
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    zeroPriceDisplaySpecificSectionIds: string[] | undefined,
    sectionsWithPlaceholderImageIds: string[] | undefined,
    placeholderImage: string | undefined
  ) {
    this.$w(SECTION_WIDGET_COMPONENT_IDS.sectionsRepeater).data = sections.map(({ id, ...rest }) => ({
      _id: id,
      preset,
      shouldDisplayCurrency,
      shouldDisplayVariantCurrency,
      zeroPriceDisplayOption,
      zeroPriceDisplaySpecificSectionIds,
      placeholderImageDisplayValue,
      sectionsWithPlaceholderImageIds,
      placeholderImage,
      ...rest,
    }));
  }
}
