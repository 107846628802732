import { MENU_NAVIGATION_PRESETS } from 'root/components/Menu Navigation/consts';
import { MENU_WIDGET_PRESET_IDS, MENU_WIDGET_PRESET_IDS_MOBILE } from 'root/utils/consts';

export const MENU_TABS_PANEL = {
  radioButtons: '#panelRadioButtonGroup1',
  alignmentThumbnails: '#panelThumbnails1',
};

export const NAVIGATION_STATES = {
  tabs: 'tabs',
  dropdown: 'dropdown',
};

export const MENU_PRESET_TO_DROPDOWN_PRESET_WEB_MAP = {
  [MENU_WIDGET_PRESET_IDS.listOneColumn]: MENU_NAVIGATION_PRESETS.dropdownCenter,
  [MENU_WIDGET_PRESET_IDS.listTwoColumn]: MENU_NAVIGATION_PRESETS.dropdownRight,
  [MENU_WIDGET_PRESET_IDS.listThreeColumn]: MENU_NAVIGATION_PRESETS.dropdownRight,
  [MENU_WIDGET_PRESET_IDS.listOneColumnWithImage]: MENU_NAVIGATION_PRESETS.dropdownLeft,
  [MENU_WIDGET_PRESET_IDS.sideBySideOneColumn]: MENU_NAVIGATION_PRESETS.dropdownLeft,
  [MENU_WIDGET_PRESET_IDS.grid]: MENU_NAVIGATION_PRESETS.dropdownRight,
};

export const MENU_PRESET_TO_DROPDOWN_PRESET_MOBILE_MAP = {
  [MENU_WIDGET_PRESET_IDS_MOBILE.cards]: MENU_NAVIGATION_PRESETS.dropdownCenter,
  [MENU_WIDGET_PRESET_IDS_MOBILE.center]: MENU_NAVIGATION_PRESETS.dropdownCenter,
  [MENU_WIDGET_PRESET_IDS_MOBILE.listOneColumnWithImage]: MENU_NAVIGATION_PRESETS.dropdownLeft,
  [MENU_WIDGET_PRESET_IDS_MOBILE.left]: MENU_NAVIGATION_PRESETS.dropdownLeft,
};

export const ALIGNMENT_INDEX_TO_DROPDOWN_PRESET_MAP = {
  0: MENU_NAVIGATION_PRESETS.dropdownLeft,
  1: MENU_NAVIGATION_PRESETS.dropdownCenter,
  2: MENU_NAVIGATION_PRESETS.dropdownRight,
};

export const DROPDOWN_PRESET_TO_ALIGNMENT_INDEX_MAP = {
  [MENU_NAVIGATION_PRESETS.dropdownLeft]: 0,
  [MENU_NAVIGATION_PRESETS.dropdownCenter]: 1,
  [MENU_NAVIGATION_PRESETS.dropdownRight]: 2,
};
