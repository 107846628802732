import type { I$W } from '@wix/yoshi-flow-editor';
import type { ELEMENT_PROPERTY } from './consts';

type ElementProperty = (typeof ELEMENT_PROPERTY)[keyof typeof ELEMENT_PROPERTY];

export const setElementDataAndCollapseIfNeeded = <T>($w: I$W, elementId: string, key: ElementProperty, value?: T) => {
  const element = $w(elementId);
  const isDeleted = element.deleted;
  const shouldShow = !!value;
  if (shouldShow) {
    isDeleted && element.restore();
    element[key] = value;
  } else if (!isDeleted) {
    element.delete();
  }
};
