import type { I$W } from '@wix/yoshi-flow-editor';

export const setPriceContainer = async (
  $item: I$W,
  componentId: string,
  price: string | undefined,
  shouldDisplayZeroPrice: boolean,
  shouldDisplayCurrency: boolean,
  priceFormatter?: (price: string, shouldDisplayVariantCurrency: boolean) => string | undefined
) => {
  const itemHasNoPrice = Number(price || '0.00') === 0;
  const priceContainer = $item(componentId);

  if (itemHasNoPrice) {
    if (!shouldDisplayZeroPrice) {
      await priceContainer.delete();
    } else {
      await priceContainer.restore();
      priceContainer.text = priceFormatter?.(price || '0.00', shouldDisplayCurrency);
    }
  }
};
