import type { Section } from 'root/types';

export const getSectionsItemsNum = (sections: Section[], minSectionSize = 0) => {
  return sections ? sections.reduce((acc, section) => acc + getSectionItemNum(section, minSectionSize), 0) : 0;
};

const getSectionItemNum = (section: Section, minSectionSize = 0) => (section.itemIds?.length || 0) + minSectionSize;

export const divideSectionsIntoTwoColumns = (sections: Section[]) => {
  const minSectionSize = 1;
  if (sections.length <= 2) {
    return Array.from({ length: 2 }, (_, i) => (sections?.[i] ? [sections[i]] : []));
  } else {
    let pointer1 = 0;
    let pointer2 = sections.length - 1;
    let leftColTotalItemsNum = getSectionItemNum(sections[pointer1]);
    let rightColTotalItemsNum = getSectionItemNum(sections[pointer2]);
    const res: Section[][] = [[sections[pointer1]], [sections[pointer2]]];
    while (pointer1 < pointer2 - 1) {
      if (rightColTotalItemsNum < leftColTotalItemsNum) {
        pointer2--;
        rightColTotalItemsNum += getSectionItemNum(sections[pointer2], minSectionSize);
        res[1].unshift(sections[pointer2]);
      } else {
        pointer1++;
        leftColTotalItemsNum += getSectionItemNum(sections[pointer1], minSectionSize);
        res[0].push(sections[pointer1]);
      }
    }
    return res;
  }
};

export const divideSectionsIntoThreeColumns = (sections: Section[]) => {
  const minSectionSize = 1;
  if (sections.length <= 3) {
    return Array.from({ length: 3 }, (_, i) => (sections?.[i] ? [sections[i]] : []));
  } else {
    let pointer1 = 0;
    let pointer2 = sections.length - 1;
    let leftColTotalItemsNum = getSectionItemNum(sections[pointer1]);
    let rightColTotalItemsNum = getSectionItemNum(sections[pointer2]);
    const totalItemsNum = getSectionsItemsNum(sections);
    const res: Section[][] = [[sections[pointer1]], [], [sections[pointer2]]];
    while (pointer1 < pointer2 - 2) {
      const leftColTotalSectionsNum = res[0].length;

      const rightColTotalSectionsNum = res[2].length;

      const minSectionsSize = sections.length - leftColTotalSectionsNum - rightColTotalSectionsNum - 3;

      const middleColTotalItemsNum = totalItemsNum - leftColTotalItemsNum - rightColTotalItemsNum + minSectionsSize;

      if (rightColTotalItemsNum < leftColTotalItemsNum && rightColTotalItemsNum < middleColTotalItemsNum) {
        pointer2--;
        rightColTotalItemsNum += getSectionItemNum(sections[pointer2], minSectionSize);
        res[2].unshift(sections[pointer2]);
      } else if (leftColTotalItemsNum <= rightColTotalItemsNum && leftColTotalItemsNum <= middleColTotalItemsNum) {
        pointer1++;
        leftColTotalItemsNum += getSectionItemNum(sections[pointer1], minSectionSize);
        res[0].push(sections[pointer1]);
      } else {
        break;
      }
    }
    res[1].push(...sections.slice(pointer1 + 1, pointer2));
    return res;
  }
};
