import type { IWixWindow } from '@wix/yoshi-flow-editor';

type WarmupData<T> = { data?: T; error?: Error };

export class WarmupDataManager {
  constructor(private warmupData: IWixWindow['warmupData'], private isSSR: boolean) {
    this.manageData = this.manageData.bind(this);
  }

  async manageData<T extends WarmupData<U>, U>(callback: () => Promise<T>, key: string) {
    let res: T | undefined;

    if (!this.isSSR) {
      res = this.warmupData.get(key);
    }
    if (!res) {
      res = await callback();
      if (this.isSSR && !res.error) {
        this.warmupData.set(key, res);
      }
    }
    return res;
  }
}
