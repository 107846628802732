import model from './model';
import { MenuNavigationController } from './menuNavigationController';
import { BiReporter } from 'root/utils/BiReporter';

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  const { location } = flowAPI?.controllerConfig?.wixCodeApi;
  const biReporter = new BiReporter(flowAPI.bi, flowAPI.controllerConfig.compId);
  const menuNavigationController = new MenuNavigationController($w, flowAPI, biReporter, $widget.props, location);

  $widget.onPropsChanged((prevProps, nextProps) => {
    menuNavigationController.init();
  });

  return {
    pageReady: async () => {
      menuNavigationController.init();
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setInitMenuFn: menuNavigationController.setInitMenuFn,
      setSetEmptyMenuFn: menuNavigationController.setSetEmptyMenuFn,
    },
  };
});
