import { didChange } from 'root/utils/lifeCycle';
import model from './model';
import type { VariantWidgetProps } from './types';
import { getPriceFormatter } from 'root/utils/currency';
import { VariantsController } from './variantsController';

export default model.createController(({ $widget, $w, flowAPI }) => {
  const priceFormatter = getPriceFormatter(flowAPI);
  const variantsController = new VariantsController($w, priceFormatter);

  $widget.onPropsChanged((prevProps, nextProps) => {
    const { priceVariants, shouldDisplayVariantCurrency, shouldDisplayZeroPrice }: VariantWidgetProps = nextProps.data;
    const {
      priceVariants: prevPriceVariants,
      shouldDisplayVariantCurrency: prevShouldDisplayVariantCurrency,
      shouldDisplayZeroPrice: prevShouldDisplayZeroPrice,
    }: VariantWidgetProps = prevProps.data;

    if (didChange(prevPriceVariants, priceVariants)) {
      variantsController.init(priceVariants, shouldDisplayVariantCurrency, shouldDisplayZeroPrice);
    }

    if (shouldDisplayVariantCurrency !== prevShouldDisplayVariantCurrency) {
      variantsController.updateCurrency(shouldDisplayVariantCurrency);
    }

    if (shouldDisplayZeroPrice !== prevShouldDisplayZeroPrice) {
      variantsController.updateShouldDisplayZeroPrice(shouldDisplayZeroPrice);
    }
  });

  return {
    pageReady: async () => {
      const { priceVariants, shouldDisplayVariantCurrency, shouldDisplayZeroPrice }: VariantWidgetProps =
        $widget.props.data;
      variantsController.init(priceVariants, shouldDisplayVariantCurrency, shouldDisplayZeroPrice);
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
