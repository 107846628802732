import model from './model';
import { ColumnsController } from './columnsController';
import type { ColumnsWidgetProps } from './types';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const { environment } = flowAPI;
  const columnsController = new ColumnsController($w, environment.isMobile);

  $widget.onPropsChanged((_, { data: nextData }: { data: ColumnsWidgetProps }) => {
    columnsController.init(nextData);
  });

  return {
    pageReady: async () => {
      columnsController.init($widget.props.data);
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
