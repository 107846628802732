import type { Section } from 'root/types';
import { divideSectionsIntoThreeColumns, divideSectionsIntoTwoColumns } from './divideSectionsIntoColumns';

export const divideSectionsIntoColumns = (colNum: number, sectionsToDivide: Section[]) => {
  switch (colNum) {
    case 1:
      return [sectionsToDivide];
    case 2:
      return divideSectionsIntoTwoColumns(sectionsToDivide);
    case 3:
      return divideSectionsIntoThreeColumns(sectionsToDivide);
    default:
      return divideSectionsIntoTwoColumns(sectionsToDivide);
  }
};
